import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: #fff;
  padding: 4px 20px;
  flex: 1;
`

const Child = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 787px;
  margin: 0 auto;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 12px;

    & > *[class^='FaqList-'] {
      padding-bottom: 15px;
    }

    & > *:not(hr) {
      max-width: 658px;
      width: 100%;
      margin: 0 auto;
    }
  }
`

const Divider = styled.hr`
  display: none;
  width: 100%;
  border: none;
  height: 2px;
  background-color: #f3f3f3;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const FaqContainer: React.FC = ({ children }) => (
  <Container>
    <Child>{children}</Child>
  </Container>
)

export default Object.assign(FaqContainer, {
  Divider,
})
