import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Title from './Title'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 10px;
  }
`

const FaqTitle = styled(Title.h2)`
  text-align: center;
`

const Paragraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
`

const FaqLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
`

interface FaqFooterProps {
  className?: string
}

const FaqFooter: React.FC<FaqFooterProps> = ({ children, className }) => (
  <Container className={className}>{children}</Container>
)

export default Object.assign(FaqFooter, {
  Title: FaqTitle,
  Paragraph,
  Link: FaqLink,
})
