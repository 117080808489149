import { PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import FaqContainer from '../../components/FaqContainer'
import FaqFooter from '../../components/FaqFooter'
import FaqList from '../../components/FaqList'
import { LayoutProps } from '../../components/Layout.context'
import { contactLink } from '../../data/menu'
import { aboutLink } from '../../data/menu.context'
import useFaqQuery from '../../hooks/useFaqQuery'

const FaqPage: React.FC<PageProps> = () => {
  const faq = useFaqQuery()

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Comment obtenir un bon de réparation ?"
          content="Save, leader de la réparation avec près de 200 magasins en France. Save est partenaire avec les grands constructeurs comme Samsung, Apple, Huawei et Xiaomi !"
        />
      </Helmet>

      <FaqContainer>
        <FaqList>
          <FaqList.Title>Une question ? Une réponse !</FaqList.Title>
          <FaqList.Items items={faq} />
        </FaqList>

        <FaqContainer.Divider />

        <FaqFooter>
          <FaqFooter.Title>Une autre question ?</FaqFooter.Title>
          <FaqFooter.Paragraph>
            Si vous ne trouvez pas la réponse à votre question n'hésitez pas à
            passer par{' '}
            <FaqFooter.Link to={contactLink}>notre page contact</FaqFooter.Link>{' '}
            et à détailler votre question dans le formulaire à disposition.
          </FaqFooter.Paragraph>
        </FaqFooter>
      </FaqContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Questions fréquentes et réponses - FAQ',
  breadcrumb: [{ label: 'À propos', href: aboutLink }, { label: 'FAQ' }],
  showHighlights: true,
}

export default Object.assign(FaqPage, {
  layoutProps,
})
