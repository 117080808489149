import React, { useMemo } from 'react'
import styled from 'styled-components'
import FaqItem from './FaqItem'
import Title from './Title'
import { IFaq } from '../hooks/useFaqQuery'

const FaqListTitle = styled(Title.h2)`
  text-align: center;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 7px;
  }
`

interface ItemsProps {
  items: IFaq[]
}

const Items: React.FC<ItemsProps> = ({ items }) => {
  const size = useMemo(() => Math.floor(items.length / 2), [items])
  const parts = useMemo(
    () => [items.slice(0, size), items.slice(size, items.length)],
    [items, size]
  )

  return (
    <Container>
      {parts.map((part, i) => (
        <Stack key={i}>
          {part.map((item, j) => (
            <FaqItem key={j} item={item} />
          ))}
        </Stack>
      ))}
    </Container>
  )
}

const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 20px;
  }
`

export default Object.assign(FaqList, {
  Title: FaqListTitle,
  Items,
})
